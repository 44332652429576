import React, { Component } from 'react';
import './NewFooter.css';

export default class NewFooter extends Component {
  render() {
    return (
      <div className="f10-footer-container">
          <div className="f10-footer-content-container">
            <div className="f10-footer-newsletter-signup">
              <div className="f10-footer-newsletter-text">
                <p>NEWSLETTER SIGNUP</p>
                <p>Education research and resources, delivered monthly</p>
                <p className="f10-footer-newsletter-text-bonus">Keep up with foundry10's latest research insights, educator tips, events, and more.</p>
                <a className="f10-footer-newsletter-subscribe-button" href="http://eepurl.com/gbRnDf" target="_blank" rel="noopener noreferrer">Subscribe Now →</a>
              </div>
              <div className="f10-footer-circle"></div>
            </div>
            <div className="f10-footer-about-us">
              <div className="f10-footer-logo">
                <img src="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+vector+logo+svg.svg" alt="foundry10 logo" height="100" width="199"/>
              </div>
              <div className="f10-footer-category-links">
                <div className="f10-footer-category-links-a">
                  <p><a href="/research" rel="noopener noreferrer">Research</a></p>
                  <p><a href="/programs" rel="noopener noreferrer">Programs</a></p>
                  <p><a href="/philanthropy" rel="noopener noreferrer">Philanthropy</a></p>
                </div>
                <div className="f10-footer-category-links-b">
                  <p><a href="/what-we-do" rel="noopener noreferrer">About</a></p>
                  <p><a href="/stories" rel="noopener noreferrer">Stories</a></p>
                  <p><a href="/resources" rel="noopener noreferrer">Resources</a></p>
                </div>
              </div>
              <div className="f10-footer-office-info">
                <div className="f10-footer-office-info-a">
                  <p><a href="tel:2066869780">206.686.9780</a></p>
                  <p><a href="mailto:info@foundry10.org">info@foundry10.org</a></p>
                  <p className='altPosition'>Mon.-Thu. <span className='nowrap'>8 a.m.-5 p.m.</span></p>
                  <p className='altPosition'>Visits by appointment only</p>
                </div>
                <div className="f10-footer-office-info-b">
                  <p>Mailing Address</p>
                  <p><a href="https://maps.app.goo.gl/r4mvTZyVSVRrggUi8" target="_blank" rel="noopener noreferrer">4244 University Way NE, #85538 Seattle, WA 98145</a></p>
                </div>
                <div className="f10-footer-office-info-b">
                  <p>Office Address</p>
                  <p><a href="https://goo.gl/maps/UodkYN9PJQSkccWy8" target="_blank" rel="noopener noreferrer">4545 Roosevelt Way, Suite #500 Seattle, WA 98105</a></p>
                </div>
                <div className="f10-footer-office-info-c">
                  <p>Mon.-Thu. <span className='nowrap'>8 a.m.-5 p.m.</span></p>
                  <p>Visits by appointment only</p>
                </div>
              </div>
              <div className="f10-footer-social-legal">
                <div className="f10-footer-social-media">
                  <a href="https://www.instagram.com/foundry10" target="_blank" rel="noopener noreferrer">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Footer/Social+Media+Icons/Instagram.png" alt="instagram logo" height="50" width="50"/>
                  </a>
                  <a href="https://www.facebook.com/foundry10" target="_blank" rel="noopener noreferrer">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Footer/Social+Media+Icons/Facebook.png" alt="facebook logo" height="50" width="50"/>
                  </a>
                  <a href="https://www.linkedin.com/company/foundry10" target="_blank" rel="noopener noreferrer">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Footer/Social+Media+Icons/Linkedin_Icon_Linkedin_50x50.png" alt="facebook logo" height="50" width="50"/>
                  </a>
                  <a href="https://www.youtube.com/channel/UCL9fFfWA1cWPY50_nDiqWRA" target="_blank" rel="noopener noreferrer">
                    <img src="https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Footer/Social+Media+Icons/Youtube.png" alt="youtube logo" height="50" width="50"/>
                  </a>
                </div>
                <div className="f10-footer-legal-links">
                  <a href="https://sites.google.com/foundry10.org/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy & Cookie Notice</a>
                  <a href="https://sites.google.com/foundry10.org/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                </div>
              </div>
            </div>
            <div className="f10-footer-divider" />
            <div className="f10-footer-land-acknowledgement">
              <h2>LAND ACKNOWLEDGEMENT</h2>
              <p>We at foundry10 acknowledge that our office is located on the traditional territories of the <a href="https://www.duwamishtribe.org" target="_blank" rel="noopener noreferrer">Duwamish</a>, <a href="https://suquamish.nsn.us" target="_blank" rel="noopener noreferrer">Suquamish</a>, <a href="https://www.stillaguamish.com" target="_blank" rel="noopener noreferrer">Stillaguamish</a>, and Coast Salish peoples of past, present, and future. We honor the tribal Elders of the Pacific Northwest and their descendants with gratitude for their stewardship of the water, the land and its inhabitants.</p>
              <p>We are committed to moving beyond words into creating the most value for Indigenous youth by expanding our work in education research, programs, and philanthropy to include more authentic partnerships with Native communities.</p>
            </div>
          </div>
        </div>
      );
    }
  }

// export default class NewFooter extends Component {
//   render() {
//     return (
//         <div className="f10-footer-container">
//           <div className="f10-footer-content-container">
//             <div className="footer-contact">
//               <img src="https://f10-image.s3.us-west-2.amazonaws.com/foundry10+vector+logo+svg.svg" alt="foundry10 logo" height="30" width="129"/>
//               <a href="/contact">Connect with us →</a>
//               <div className="footer-privacy">
//                 <a href="https://sites.google.com/foundry10.org/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy & Cookie Notice</a>
//                 <a href="https://sites.google.com/foundry10.org/terms-of-use" target="_blank" rel="noopener noreferrer">Terms of Use</a>
//               </div>
//             </div>
//             <div className="footer-connect">
//               <h3>Connect</h3>
//               <div className="footer-connect-links">
//                 <p><a href="https://www.instagram.com/foundry10" target="_blank" rel="noopener noreferrer">Instagram</a></p>
//                 <p><a href="https://www.facebook.com/foundry10" target="_blank" rel="noopener noreferrer">Facebook</a></p>
//                 <p><a href="https://twitter.com/foundry10ed" target="_blank" rel="noopener noreferrer">Twitter</a></p>
//                 <p><a href="https://medium.com/the-foundry10-voice" target="_blank" rel="noopener noreferrer">Medium</a></p>
//                 <p><a href="https://www.youtube.com/channel/UCL9fFfWA1cWPY50_nDiqWRA" target="_blank" rel="noopener noreferrer">Youtube</a></p>          
//               </div>
//             </div>
//             <div className="footer-office">
//               <h3>Office</h3>
//               <p>4545 Roosevelt Way</p>
//               <p>Suite #500</p>
//               <p>Seattle, WA 98105</p>
//               <p>Visits by appointment only</p>
//             </div>
//             <div className="footer-office-contact">
//               <h3>Contact</h3>
//               <p>206.686.9780</p>
//               <p>info@foundry10.org</p>
//               <p>M-Th 10am-5pm</p>
//             </div>
//             <div className="footer-landAcknowledgement">
//               <h3>Land Acknowledgement</h3>
//               <p>We at foundry10 acknowledge that our office is located on the traditional territories of the <a href="https://www.duwamishtribe.org" target="_blank" rel="noopener noreferrer">Duwamish</a>, <a href="https://suquamish.nsn.us" target="_blank" rel="noopener noreferrer">Suquamish</a>, <a href="https://www.stillaguamish.com" target="_blank" rel="noopener noreferrer">Stillaguamish</a>, and Coast Salish peoples of past, present, and future. We honor the tribal Elders of the Pacific Northwest and their descendants with gratitude for their stewardship of the water, the land and its inhabitants. We are committed to moving beyond words into creating the most value for Indigenous youth by expanding our work in education research, programs, and philanthropy to include more authentic partnerships with Native communities.</p>
//             </div>
//           </div>
//         </div>
//     );
//   }
// }